<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center m-0 p-0"
  >
    <img
      src="../assets/logo/logo-lets-coffee-white.webp"
      alt="fg"
      class="htp-image"
    />
    <div class="htp-title">loading...</div>
    <div class="demo-font">Demo Only</div>
    <img
      src="../assets/logo/logo-arwir-white.webp"
      alt="logo AR WIR"
      class="landing-arwir-logo-white"
    />
    <div
      class="loadingBarContainer mb-3 mt-5 d-flex flex-column align-items-center"
    >
      <img
        v-if="loadingComplete"
        src="../assets/logo/loadingBar2.svg"
        alt="loading"
      />
      <img
        v-else-if="loadingSequence == 0"
        src="../assets/logo/loadingBar0.svg"
        alt="loading"
      />
      <img v-else src="../assets/logo/loadingBar1.svg" alt="loading" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingPage",
  props: ["loadingComplete"],
  data() {
    return {
      loadingSequence: 0,
    };
  },
  methods: {
    setLoadingSequence() {
      setTimeout(() => {
        this.loadingSequence = 1;
      }, 3000);
    },
  },
  mounted() {
    this.setLoadingSequence();
  },
};
</script>
