<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center m-0 p-0"
  >
    <!-- <img
      src="../assets/logo/logo-happy.webp"
      alt="top logo"
      class="intro-top-logo"
    />
    <img
      src="../assets/logo/logo-lets-coffee-white.webp"
      alt="fg"
      class="intro-header mt-2"
    />
    <div class="mt-5 intro-text">
      we're committed to transparency and support for growers. helping build
      sustainable and responsible businesses around the world makes us happy.
    </div> -->
    <img
      src="../assets/img/img-intro-full.webp"
      alt="full img"
      class="intro-full-img"
    />
    <div class="filler-bottom"></div>
    <div
      @click.prevent="setPageStatus"
      class="button-position"
      :class="{
        'button-purple': buttonEnabled,
        'button-purple-disabled': !buttonEnabled,
      }"
    >
      next
    </div>
    <div class="demo-font">Demo Only</div>

    <img
      src="../assets/logo/logo-arwir-white.webp"
      alt="logo AR WIR"
      class="landing-arwir-logo-white"
    />
  </div>
</template>

<script>
export default {
  name: "IntroPage",
  data() {
    return {
      gender: 1,
    };
  },
  computed: {
    buttonEnabled() {
      if (this.gender != 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    setPageStatus() {
      if (this.buttonEnabled) this.$store.commit("global/setPageStatus", 2);
    },
  },
};
</script>
