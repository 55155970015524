<template>
  <div v-if="isPortrait" class="h-100 baseBg">
    <div :class="{ invisible: !isVisible, versionClass: isVisible }">
      {{ version }}
    </div>
    <div class="main-base-bg">
      <img
        class="img-bg-1"
        :class="{ 'move-img-1': moveTransition }"
        src="../assets/img/img1.webp"
        alt=""
      />
      <img
        class="img-bg-2"
        :class="{ 'move-img-2': moveTransition }"
        src="../assets/img/img2.webp"
        alt=""
      />
      <img
        class="img-bg-3"
        :class="{ 'move-img-3': moveTransition }"
        src="../assets/img/img3.webp"
        alt=""
      />
      <img
        class="img-bg-4"
        :class="{ 'move-img-4': moveTransition }"
        src="../assets/img/img4.webp"
        alt=""
      />
      <img
        class="img-bg-5"
        :class="{ 'move-img-5': moveTransition }"
        src="../assets/img/img5.webp"
        alt=""
      />
    </div>
    <div class="toggler" @click.stop="toggleVisibility"></div>
    <LandingPage v-if="pageStatus == 0" class="h-100" />
    <IntroPage v-else-if="pageStatus == 1" class="h-100" />
    <FilterPage v-else-if="pageStatus == 2" class="h-100" :moveOut="moveOut" />
    <TemplateScreen v-else-if="pageStatus == 3" class="h-100 template-screen" />
    <HTPPage
      v-else-if="pageStatus == 4"
      class="h-100"
      :handleTakePicture="handleTakePicture"
    />
    <LoadingPage
      v-else-if="pageStatus == 5"
      class="h-100"
      :loadingComplete="loadingComplete"
    />
    <PreviewPage
      v-else-if="pageStatus == 6"
      class="h-100"
      :processedImageSrc="processedImageSrc"
      :backToHome="backToHome"
    />
  </div>

  <div
    v-else
    class="landscapeDisplay d-flex justify-content-center align-items-center"
  >
    <img src="../assets/icon/rotate.png" alt="landscape" class="rotate-img" />
  </div>
</template>

<script>
// @ is an alias to /src
import LandingPage from "@/components/LandingPage.vue";
import IntroPage from "@/components/IntroPage.vue";
import FilterPage from "@/components/FilterPage.vue";
import TemplateScreen from "@/components/TemplateScreen.vue";
import HTPPage from "@/components/HTPPage.vue";
import LoadingPage from "@/components/LoadingPage.vue";
import PreviewPage from "@/components/PreviewPage.vue";
import { mapState } from "vuex";

export default {
  name: "HomeView",
  components: {
    LandingPage,
    IntroPage,
    FilterPage,
    TemplateScreen,
    HTPPage,
    LoadingPage,
    PreviewPage,
  },
  data() {
    return {
      isVisible: false,
      processedImageSrc: "",
      photoSrc: "",
      templateSrc: "",
      moveTransition: false,
      isPortrait: window.matchMedia("(orientation: portrait)").matches,
      loadingComplete: false,
    };
  },
  computed: {
    ...mapState({
      pageStatus: (state) => state.global.pageStatus,
      selectedGender: (state) => state.global.selectedGender,
      selectedTemplate: (state) => state.global.selectedTemplate,
      version: (state) => state.global.version,
    }),
    frameFilter() {
      if (this.selectedGender == "male")
        return this.$store.state.photo.frameGroupData.group1;
      else if (this.selectedGender == "female")
        return this.$store.state.photo.frameGroupData.group2;
      return [];
    },
  },
  methods: {
    checkOrientation() {
      this.isPortrait = window.matchMedia("(orientation: portrait)").matches;
    },
    handleOrientationChange() {
      this.checkOrientation();
    },
    async toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    async exeFaceSwap() {
      const payload = {
        source_image: this.photoSrc,
        target_image: this.templateSrc,
        source_faces_index: [0],
        face_index: [this.targetFaceIndex],
        upscaler: "None",
        scale: 1,
        upscale_visibility: 1,
        face_restorer: "GFPGAN",
        restorer_visibility: 1,
        codeformer_weight: 0.5,
        restore_first: 1,
        model: "inswapper_128.onnx",
        gender_source: 0,
        gender_target: 0,
        save_to_file: 0,
        result_file_path: "",
        device: "CUDA",
        mask_face: 1,
        select_source: 0,
        face_model: "None",
      };
      this.$store
        .dispatch("photo/hitStableDiffusion", payload)
        .then((response2) => {
          if (response2.status == "full") return;
          const formattedBase64 = `data:image/jpeg;base64,${response2.data.image}`;
          this.processedImageSrc = formattedBase64;
          this.$store.commit("global/setPageStatus", 6);

          this.photoSrc = "";
          this.templateSrc = "";
          this.loadingComplete = true;
        });
    },
    moveOut(payload) {
      this.moveTransition = payload;
    },
    // image related (create, check, download, convert)
    iOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    manageInput() {
      // Check if there are any existing input elements
      const existingInputs = document.querySelectorAll('input[type="file"]');
      if (existingInputs.length > 0) {
        existingInputs.forEach((input) => {
          input.parentNode.removeChild(input);
        });
      }
    },
    async takePicture() {
      if (this.selectedTemplate !== "") {
        if (this.iOS()) {
          this.manageInput();
          const inputElement = document.createElement("input");
          inputElement.type = "file";
          inputElement.accept = "image/*";
          inputElement.capture = "camera";
          inputElement.style.display = "none";
          return new Promise((resolve, reject) => {
            document.body.appendChild(inputElement);
            inputElement.addEventListener(
              "change",
              function handleChange(event) {
                inputElement.removeEventListener("change", handleChange);
                const selectedImage = event.target.files[0];
                if (selectedImage) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const base64Image = e.target.result.split(",")[1];
                    const formattedBase64Image = `data:image/jpeg;base64,${base64Image}`;
                    resolve(formattedBase64Image);
                  };
                  reader.onerror = (error) => {
                    console.error("Error reading file:", error);
                    reject(error);
                  };
                  reader.readAsDataURL(selectedImage);
                } else {
                  reject(new Error("No image selecteda"));
                }
              }
            );
            // Trigger a click on the input element to open the file picker
            inputElement.click();
          })
            .finally(() => {
              // Remove the input element from the DOM in the finally block
              document.body.removeChild(inputElement);
            })
            .catch((error) => {
              console.error("Error loading image:", error);
            });
        } else {
          const inputElement = document.createElement("input");
          inputElement.type = "file";
          inputElement.accept = "image/*";
          inputElement.capture = "camera";
          inputElement.click();

          return new Promise((resolve, reject) => {
            inputElement.addEventListener("change", (event) => {
              const selectedImage = event.target.files[0];
              if (selectedImage) {
                const reader = new FileReader();

                reader.onload = (e) => {
                  const base64Image = e.target.result.split(",")[1];
                  const formattedBase64Image = `data:image/jpeg;base64,${base64Image}`;
                  resolve(formattedBase64Image);
                };

                reader.readAsDataURL(selectedImage);
              } else {
                reject(new Error("No image selecteda"));
              }
            });
          });
        }
      } else {
        return Promise.reject(new Error("No template selecteda"));
      }
    },
    async convertToBase64() {
      console.log(this.frameFilter);
      const foundObject = this.frameFilter.find(
        (obj) => obj.id === this.selectedTemplate
      );
      console.log(foundObject);
      let imagePath = foundObject.srcOri;
      this.targetFaceIndex = foundObject.faceIndexTarget;
      try {
        const loadImage = (src) => {
          return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
            image.src = src;
          });
        };
        const image = await loadImage(imagePath);
        image.src = imagePath;

        // Create a canvas element
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the image onto the canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);

        // Get the base64-encoded data from the canvas
        const base64Image = canvas.toDataURL("image/jpeg");
        return base64Image;
      } catch (error) {
        console.error("Error converting image to Base64:", error);
        throw error;
      }
    },
    handleTakePicture() {
      this.takePicture()
        .then((base64Image) => {
          this.$store.commit("global/setPageStatus", 5);
          this.moveTransition = false;
          this.photoSrc = base64Image;
          return this.convertToBase64(this.selectedTemplate);
        })
        .then((templateBase64) => {
          this.templateSrc = templateBase64;
          this.exeFaceSwap();
        })
        .catch((error) => {
          console.error("Error API:", error.message);
          // this.decreaseServerCounting();
          this.errorReturn = true;
        });
    },

    // return
    backToHome() {
      this.$store.commit("global/totalResetState");
      this.processedImageSrc = "";
      this.photoSrc = "";
      this.templateSrc = "";
      this.moveTransition = false;
      this.loadingComplete = false;
    },
  },
  mounted() {
    this.checkOrientation();
    window
      .matchMedia("(orientation: portrait)")
      .addEventListener("change", this.handleOrientationChange);
  },
  beforeDestroy() {
    window
      .matchMedia("(orientation: portrait)")
      .removeEventListener("change", this.handleOrientationChange);
  },
};
</script>
