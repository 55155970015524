<template>
  <div class="landing-page d-flex justify-content-center w-100">
    <img src="../assets/bg/bg-landing.webp" alt="bg" class="landing-bg" />
    <img
      src="../assets/logo/logo-happy.webp"
      alt="top logo"
      class="landing-top-logo"
    />
    <img
      src="../assets/img/img-3-happy.webp"
      alt="fg"
      class="landing-product"
    />
    <div @click="setPageStatus" class="landing-button-position button-purple">
      launch
    </div>
    <div class="demo-font">Demo Only</div>
    <img
      src="../assets/logo/logo-arwir-white.webp"
      alt="logo AR WIR"
      class="landing-arwir-logo-white"
    />
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  methods: {
    setPageStatus() {
      console.log("clicked");
      this.$store.commit("global/setPageStatus", 1);
    },
  },
};
</script>
