<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center m-0 p-0"
  >
    <div class="htp-title">introduce yourself</div>

    <div class="descFont">To improve photo accuracy results</div>

    <div class="btn-group row justify-content-center w-100 pt-4" role="group">
      <!-- Radio button 1 -->
      <div class="col-1"></div>

      <input
        type="radio"
        id="radio1"
        v-model="localGender"
        value="male"
        class="d-none"
        @click="setGender('male')"
      />
      <label
        for="radio1"
        class="custom-radio-label col-4"
        :class="{ active: localGender === 'male' }"
      >
        <div class="col-12 text-center">
          <img
            v-if="localGender === 'male'"
            src="../assets/icon/icon-male-active.webp"
            alt="Male Active"
            class="img-fluid shadowBox"
          />
          <img
            v-else
            src="../assets/icon/icon-male-inactive.webp"
            alt="Male Not Active"
            class="img-fluid"
          />
          <p class="genderTextFont mt-2">Men</p>
        </div>
      </label>

      <!-- Radio button 2 -->
      <input
        type="radio"
        id="radio2"
        v-model="localGender"
        value="female"
        class="d-none"
        @click="setGender('female')"
      />
      <label
        for="radio2"
        class="custom-radio-label col-4"
        :class="{ active: localGender === 'female' }"
      >
        <div class="col-12 text-center">
          <img
            v-if="localGender === 'female'"
            src="../assets/icon/icon-female-active.webp"
            alt="Female Active"
            class="img-fluid shadowBox"
          />
          <img
            v-else
            src="../assets/icon/icon-female-inactive.webp"
            alt="Female Not Active"
            class="img-fluid"
          />
          <p class="genderTextFont mt-2">Women</p>
        </div>
      </label>
      <div class="col-1"></div>
    </div>
    <div class="filler-bottom"></div>
    <div
      @click.prevent="setPageStatus"
      class="button-position"
      :class="{
        'button-purple': buttonEnabled,
        'button-purple-disabled': !buttonEnabled,
      }"
    >
      choose
    </div>
    <div class="demo-font">Demo Only</div>

    <img
      src="../assets/logo/logo-arwir-white.webp"
      alt="logo AR WIR"
      class="landing-arwir-logo-white"
    />
  </div>
</template>

<script>
export default {
  name: "FilterPage",
  props: ["moveOut"],
  data() {
    return {
      localGender: null,
    };
  },
  computed: {
    buttonEnabled() {
      if (this.localGender != null) {
        return true;
      }
      return false;
    },
  },
  methods: {
    setPageStatus() {
      if (this.buttonEnabled) {
        this.$store.commit("global/setPageStatus", 3);
        this.moveOut(true);
      }
    },
    setGender(value) {
      this.$store.commit("global/setGender", value);
    },
  },
};
</script>
