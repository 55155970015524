var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedGender == 'male')?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center m-0 p-0"},[_c('p',{staticClass:"titleFont"},[_vm._v("choose frame")]),_c('p',{staticClass:"descFont"},[_vm._v("choose the frame you want")]),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"templateFrame",class:{ highlighted: _vm.selectedTemplate === 'm1' },attrs:{"src":require("../assets/webp/m1.webp"),"alt":"1"},on:{"click":function($event){return _vm.setSelectedTemplate('m1')}}})]),_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"templateFrame",class:{ highlighted: _vm.selectedTemplate === 'm2' },attrs:{"src":require("../assets/webp/m1.webp"),"alt":"2"},on:{"click":function($event){return _vm.setSelectedTemplate('m2')}}})])]),_c('div',{staticClass:"row justify-content-between mt-4"},[_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"templateFrame",class:{ highlighted: _vm.selectedTemplate === 'm3' },attrs:{"src":require("../assets/webp/m1.webp"),"alt":"3"},on:{"click":function($event){return _vm.setSelectedTemplate('m3')}}})]),_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"templateFrame",class:{ highlighted: _vm.selectedTemplate === 'm4' },attrs:{"src":require("../assets/webp/m1.webp"),"alt":"4"},on:{"click":function($event){return _vm.setSelectedTemplate('m4')}}})])]),_c('div',{staticStyle:{"height":"100px"}}),_c('div',{staticClass:"button-position",class:{
      'button-purple': this.selectedTemplate != 0,
      'button-purple-disabled': this.selectedTemplate == 0,
    },on:{"click":function($event){$event.preventDefault();return _vm.setPageStatus.apply(null, arguments)}}},[_vm._v(" choose ")]),_c('div',{staticClass:"demo-font"},[_vm._v("Demo Only")]),_c('img',{staticClass:"landing-arwir-logo-white",attrs:{"src":require("../assets/logo/logo-arwir-white.webp"),"alt":"logo AR WIR"}})]):(_vm.selectedGender == 'female')?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center m-0 p-0"},[_c('p',{staticClass:"titleFont"},[_vm._v("choose frame")]),_c('p',{staticClass:"descFont"},[_vm._v("choose the frame you want")]),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"templateFrame",class:{ highlighted: _vm.selectedTemplate === 'f1' },attrs:{"src":require("../assets/webp/f1.webp"),"alt":"1"},on:{"click":function($event){return _vm.setSelectedTemplate('f1')}}})]),_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"templateFrame",class:{ highlighted: _vm.selectedTemplate === 'f2' },attrs:{"src":require("../assets/webp/f1.webp"),"alt":"2"},on:{"click":function($event){return _vm.setSelectedTemplate('f2')}}})])]),_c('div',{staticClass:"row justify-content-between mt-4"},[_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"templateFrame",class:{ highlighted: _vm.selectedTemplate === 'f3' },attrs:{"src":require("../assets/webp/f1.webp"),"alt":"3"},on:{"click":function($event){return _vm.setSelectedTemplate('f3')}}})]),_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"templateFrame",class:{ highlighted: _vm.selectedTemplate === 'f4' },attrs:{"src":require("../assets/webp/f1.webp"),"alt":"4"},on:{"click":function($event){return _vm.setSelectedTemplate('f4')}}})])]),_c('div',{staticStyle:{"height":"100px"}}),_c('div',{staticClass:"button-position",class:{
      'button-purple': this.selectedTemplate != 0,
      'button-purple-disabled': this.selectedTemplate == 0,
    },on:{"click":function($event){$event.preventDefault();return _vm.setPageStatus.apply(null, arguments)}}},[_vm._v(" choose ")]),_c('div',{staticClass:"demo-font"},[_vm._v("Demo Only")]),_c('img',{staticClass:"landing-arwir-logo-white",attrs:{"src":require("../assets/logo/logo-arwir-white.webp"),"alt":"logo AR WIR"}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }