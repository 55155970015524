import axios from "axios";
const apiUrl = "https://sd.wirglobal.com/reactor/image";

const state = {
  serverLimit: 0,
  connectingTo: {},
  frameGroupData: {
    group1: [
      {
        id: "m1",
        // src: require("@/assets/webp/1.png"),
        src: require("@/assets/webp/m1.webp"),
        srcOri: require("@/assets/allTemplate/m1.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 5,
      },
      {
        id: "m2",
        // src: require("@/assets/webp/1.png"),
        src: require("@/assets/webp/m1.webp"),
        srcOri: require("@/assets/allTemplate/m1.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 5,
      },
      {
        id: "m3",
        // src: require("@/assets/webp/1.png"),
        src: require("@/assets/webp/m1.webp"),
        srcOri: require("@/assets/allTemplate/m1.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 5,
      },
      {
        id: "m4",
        // src: require("@/assets/webp/1.png"),
        src: require("@/assets/webp/m1.webp"),
        srcOri: require("@/assets/allTemplate/m1.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 5,
      },
    ],
    group2: [
      {
        id: "f1",
        // src: require("@/assets/webp/1.png"),
        src: require("@/assets/webp/f1.webp"),
        srcOri: require("@/assets/allTemplate/f1.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 5,
      },
      {
        id: "f2",
        // src: require("@/assets/webp/1.png"),
        src: require("@/assets/webp/f1.webp"),
        srcOri: require("@/assets/allTemplate/f1.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 5,
      },
      {
        id: "f3",
        // src: require("@/assets/webp/1.png"),
        src: require("@/assets/webp/f1.webp"),
        srcOri: require("@/assets/allTemplate/f1.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 5,
      },
      {
        id: "f4",
        // src: require("@/assets/webp/1.png"),
        src: require("@/assets/webp/f1.webp"),
        srcOri: require("@/assets/allTemplate/f1.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 5,
      },
    ],
  },
};

const mutations = {
  setServerSetting(state, payload) {
    state.serverLimit = payload;
  },
  setConnectingTo(state, payload) {
    state.connectingTo = payload;
  },
};

const actions = {
  // Action to perform a POST request with a JSON body
  async hitStableDiffusion(context, payload) {
    return axios({
      method: "post",
      url: apiUrl,
      // url: state.connectingTo.url,
      // url: 'https://stest.duajari.online/reactor/image',
      // url: "https://s2.duajari.online/reactor/image",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
