import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import device from "vue-device-detector";
import browserDetect from "vue-browser-detect-plugin";

import "./css/index.css";

Vue.config.productionTip = false;
Vue.use(device);
Vue.use(browserDetect);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
