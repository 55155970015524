<template>
  <div
    v-if="selectedGender == 'male'"
    class="d-flex flex-column justify-content-center align-items-center m-0 p-0"
  >
    <p class="titleFont">choose frame</p>
    <p class="descFont">choose the frame you want</p>
    <div class="row justify-content-between">
      <div class="col-6">
        <img
          @click="setSelectedTemplate('m1')"
          src="../assets/webp/m1.webp"
          alt="1"
          class="templateFrame"
          :class="{ highlighted: selectedTemplate === 'm1' }"
        />
      </div>
      <div class="col-6">
        <img
          @click="setSelectedTemplate('m2')"
          src="../assets/webp/m1.webp"
          alt="2"
          class="templateFrame"
          :class="{ highlighted: selectedTemplate === 'm2' }"
        />
      </div>
    </div>
    <div class="row justify-content-between mt-4">
      <div class="col-6">
        <img
          @click="setSelectedTemplate('m3')"
          src="../assets/webp/m1.webp"
          alt="3"
          class="templateFrame"
          :class="{ highlighted: selectedTemplate === 'm3' }"
        />
      </div>
      <div class="col-6">
        <img
          @click="setSelectedTemplate('m4')"
          src="../assets/webp/m1.webp"
          alt="4"
          class="templateFrame"
          :class="{ highlighted: selectedTemplate === 'm4' }"
        />
      </div>
    </div>
    <div style="height: 100px"></div>

    <div
      @click.prevent="setPageStatus"
      class="button-position"
      :class="{
        'button-purple': this.selectedTemplate != 0,
        'button-purple-disabled': this.selectedTemplate == 0,
      }"
    >
      choose
    </div>
    <div class="demo-font">Demo Only</div>

    <img
      src="../assets/logo/logo-arwir-white.webp"
      alt="logo AR WIR"
      class="landing-arwir-logo-white"
    />
  </div>
  <div
    v-else-if="selectedGender == 'female'"
    class="d-flex flex-column justify-content-center align-items-center m-0 p-0"
  >
    <p class="titleFont">choose frame</p>
    <p class="descFont">choose the frame you want</p>
    <div class="row justify-content-between">
      <div class="col-6">
        <img
          @click="setSelectedTemplate('f1')"
          src="../assets/webp/f1.webp"
          alt="1"
          class="templateFrame"
          :class="{ highlighted: selectedTemplate === 'f1' }"
        />
      </div>
      <div class="col-6">
        <img
          @click="setSelectedTemplate('f2')"
          src="../assets/webp/f1.webp"
          alt="2"
          class="templateFrame"
          :class="{ highlighted: selectedTemplate === 'f2' }"
        />
      </div>
    </div>
    <div class="row justify-content-between mt-4">
      <div class="col-6">
        <img
          @click="setSelectedTemplate('f3')"
          src="../assets/webp/f1.webp"
          alt="3"
          class="templateFrame"
          :class="{ highlighted: selectedTemplate === 'f3' }"
        />
      </div>
      <div class="col-6">
        <img
          @click="setSelectedTemplate('f4')"
          src="../assets/webp/f1.webp"
          alt="4"
          class="templateFrame"
          :class="{ highlighted: selectedTemplate === 'f4' }"
        />
      </div>
    </div>
    <div style="height: 100px"></div>

    <div
      @click.prevent="setPageStatus"
      class="button-position"
      :class="{
        'button-purple': this.selectedTemplate != 0,
        'button-purple-disabled': this.selectedTemplate == 0,
      }"
    >
      choose
    </div>
    <div class="demo-font">Demo Only</div>

    <img
      src="../assets/logo/logo-arwir-white.webp"
      alt="logo AR WIR"
      class="landing-arwir-logo-white"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TemplateScreen",
  data() {
    return {
      columnNumber: 3,
      selectedTemplate: 0,
    };
  },
  computed: {
    ...mapState({
      selectedGender: (state) => state.global.selectedGender,
    }),
  },
  methods: {
    setSelectedTemplate(inputNumber) {
      this.selectedTemplate = inputNumber;
    },
    setPageStatus() {
      if (this.selectedTemplate != 0) {
        this.$store.commit("global/setSelectedTemplate", this.selectedTemplate);
        this.$store.commit("global/setPageStatus", 4);
      }
    },
  },
};
</script>
