<template>
  <div
    class="h-100 blurResultBg paddingTop15 d-flex justify-content-center noScroll h-100"
  >
    <div class="coverReal"></div>
    <div id="printedArea" ref="printedArea">
      <img class="resultImage" :src="processedImageSrc" alt="" />
    </div>
    <div class="d-flex flex-column align-items-center">
      <img class="previewImage" :src="processedImageSrc" alt="" />
      <img
        src="../assets/icon/icon-button-download.webp"
        alt=""
        class="overlapDownload"
        @click="downloadTheCanvas"
      />
      <div class="button-purple home-button" @click="backToHome">home</div>
      <div class="demo-font">Demo Only</div>

      <img
        src="../assets/logo/logo-arwir-white.webp"
        alt="logo AR WIR"
        class="landing-arwir-logo-white"
      />
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";

export default {
  name: "PreviewPage",
  props: ["processedImageSrc", "backToHome"],
  data() {
    return {
      imageDataUrlDownload: "",
    };
  },
  methods: {
    async downloadTheCanvas() {
      const divToDownload = this.$refs.printedArea;
      if (this.imageDataUrlDownload !== "") {
        this.executeDownloadImage(this.imageDataUrlDownload);
        return;
      }
      html2canvas(divToDownload, { width: 1080, height: 1920, scale: 1 }).then(
        // html2canvas(divToDownload, { width: 1205, height: 1819, scale: 1 }).then(
        (canvas) => {
          this.imageDataUrlDownload = canvas.toDataURL("image/png");
          const imageDataUrl = canvas.toDataURL("image/png");
          this.executeDownloadImage(imageDataUrl);
        }
      );
    },
    async executeDownloadImage(imgValue) {
      const now = new Date();
      const simplifiedDate = [now.getTime()]
        .map((n) => n.toString().padStart(2, "0"))
        .join("");
      const downloadLink = document.createElement("a");
      downloadLink.href = imgValue;
      downloadLink.download = `demo_${simplifiedDate}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
};
</script>
