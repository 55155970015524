<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center m-0 p-0"
  >
    <div class="htp-title">how to use</div>
    <img src="../assets/img/img-htp.webp" alt="fg" class="htp-image mt-4" />
    <div class="filler-bottom"></div>

    <div
      @click.prevent="handleTakePicture"
      class="button-position button-purple button-camera"
    >
      <img src="../assets/icon/icon-camera.webp" alt="" class="icon-camera" />
      camera
    </div>
    <div class="demo-font">Demo Only</div>

    <img
      src="../assets/logo/logo-arwir-white.webp"
      alt="logo AR WIR"
      class="landing-arwir-logo-white"
    />
  </div>
</template>

<script>
export default {
  name: "HTPPage",
  props: ["handleTakePicture"],
  data() {
    return {
      gender: 1,
    };
  },
  computed: {
    buttonEnabled() {
      if (this.gender != 0) {
        return true;
      }
      return false;
    },
  },
};
</script>
